// This file is the only way I could figure out how to override the
// default bootstrap breakpoints. 
// Note: Facebook's docs recommend this way.
//  https://facebook.github.io/create-react-app/docs/adding-bootstrap


// Override default variables before the import
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) ;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';