
/* We have the age old problem of z-index management -- this is the best
way I found to handle it.
The reason I am starting at 100,000 is because the z-index of popups increment,
and this is sure to avoid collisions 
*/
body {
  --navbar-z: 120000;
  --resizer-z: 130000;
  --layer-switcher-z: 100000;
  --non-spatial-z: 100000;
  --non-spatial-content-z: 110000;
  --loading-screen-z: 150000;
  --expanded-timeline: 110000;
  --help-modal: 140000;
  --modal-backdrop: 115000;

}

html, body, .root {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overscroll-behavior: none;
  position: fixed; 
}



/* This prevents a bug on safari and firefox with the legend */
.ol-control.ol-legend {
 overflow-y: hidden !important;
}

.navbar {
  max-height: 65px;
  height: 65px;
  z-index: var(--navbar-z);
}

.nav-fill .nav-item {
  padding: 0 10px;
}

#nav-feature-filter {
  position: absolute;
  right: 230px;
}

#nav-toggle-controls {
  position: absolute;
  right: 55px;
}

#nav-help-modal {
  position: absolute;
  right: 5px;
}

.navbar-toggler {
  margin: 5px;
  height: auto;
}

/* Adding css to third party library, "ol-layerswitcher */
.layer-switcher{
  z-index: var(--layer-switcher-z);
  bottom: unset !important;
  max-height: 75vh;
}

.layer-switcher .panel{
  padding: 5px 1em 0 0 !important;
  max-height: inherit !important;
  margin-top: 40px !important;
}

.layer-switcher .group.layer-switcher-close button {
  background-color: white;
}

.layer-switcher .group.layer-switcher-open button {
  background-color: white;
}

.layer-switcher:hover {
  z-index: 1000000;
}

.layer-switcher>button{
  background-image: none;
  background-color: white !important;

  width: 38px !important;
  height: 38px !important;
}

.slider.slider-horizontal {
  width:155px !important;
}

/* ToggleMapControls.js */
.toggle-controls-button {
  width: 166px;
}

/* When navbar collapses */
@media (max-width: 1399px){

  .zoom-to-extent {
    top: 4.75em !important;
  }

  .graph-arrow, .graph-close-button {
    opacity: 0.4 !important;
  }

  .graph-arrow:focus, .graph-close-button:focus {
    opacity: 0.4 !important;
    animation-duration: 2s;
    animation-delay: 0.5s ;
  }

  .graph-arrow:active, .graph-close-button:active {
    opacity: 1 !important;

  }

  .toggle-controls-button{
    position: inherit;
  }

  #nav-toggle-controls {
  position: inherit;
  }

  #nav-feature-filter{
    position: inherit;
  }

  #nav-help-modal{
    position: inherit;

  }

  #help-modal{
    z-index: var(--help-modal) !important;
  }

  #settings-dropdown{
    width: 190px !important;
  }

  .collapsing {
  background-color: rgba(252, 253, 253, 0.95);
  margin: 7px -10px 10px calc(100% - 300px);
  padding: 15px;
  width: 250px;
  }

  .navbar-collapse.show {
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    background-color: rgba(252, 253, 253, 0.95);
    margin: 7px -10px 10px calc(100% - 300px);
    padding: 15px;
    width: 250px;
    z-index: var(--navbar-z);
  }


  .form-inline button {
    margin: 10px;
  }
  .nav-fill .nav-item {
    padding: 6px 0;
  }
  .dropdown{
    width: 190px;
    margin-left: auto;
    margin-right: auto;
  }
}

.model-map {
  position: absolute;
  top: 65px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /* This transition is for the timeline - makes map rerender weird though */
  /* -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; */
}

.layerswitcher-opacity {
  display:none;
}

.ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  border-radius: 5px;
  border: 2px solid #cccccc;
  bottom: 12px;
  left: -50px;
  overflow: auto;
  user-select: none;
  min-height: 160px;
  height: 160px;
  max-height: 1000px;
  min-width: 300px;
  width: 300px;
  max-width: 2000px;

}

.ol-popup.shell {
  overflow: hidden;
  border: none;
}

.info-container {
  left: 0px;
  bottom: 0px;
  width: inherit;
  height: inherit;
  padding: 10px 10px 0 30px;
}

.single-popup-header {
  background-color: #d7e8f4;
  margin: -10px -10px 5px -30px;
  padding: 10px 10px 1px 30px;
}

.ol-popup-closer {
  position: absolute;
  padding: 5px;
  border:none;
  cursor: pointer;
  background-color: transparent;
  z-index: 1;
}

.ol-popup-closer:focus {
  outline: none;
}

.list-cap {
  display: block;
  font-weight: bold;
}

/* caret in the dropdown */
.dropdown-toggle::after {
  position: absolute;
  right: 10px;
  margin-top: -12px;
}

#settings::after {
  display: none;
}

#settings-menu.show {
  padding: 10px;
}

/* ElementSelect.js */
.dropdown-box {
  width: 190px;
  font-size: 16px !important;
  text-align: left !important;
}

/* PopupContent.js & HoverContent.js */
.state-bullet {
  font-size: 7px;
  vertical-align: 3px;
  display: table-cell;
}

.popup-text {
  display: table-cell;
  color: black;
}

.dropdown-display {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  display: block;
  white-space: nowrap;
}

#settings-dropdown {
  width: inherit;
}

.resizer {
  width: 0px;
  height: 0px;
  background: transparent;
  position: absolute;
  z-index: var(--resizer-z);
}

.resizer.top-right {
  border-top: 15px solid transparent;
  border-left: 15px solid transparent;
  right: 0px;
  top: 0px;
  cursor: nesw-resize;
}

.resizer.bottom-right {
 
  border-bottom: 15px solid transparent;
  border-left: 15px solid transparent;
  right: 0px;
  bottom: 0px;
  cursor: nwse-resize;
}

.resizer.bottom-left {
  border-bottom: 15px solid transparent;
  border-right: 15px solid transparent;
  left: 0px;
  bottom: 0px;
  cursor: nesw-resize;
}

.resizer.top-left {
  border-top: 8px solid transparent;
  border-right: 8px solid transparent;
  left: 0px;
  top: 0px;
  cursor: nwse-resize;
}

.resizer.bottom {
  border-bottom: 15px solid transparent;
  cursor: ns-resize;
  width: 100%;
  bottom: 0px;
}

.popup-text:first-line {
  padding-left: 0px;
}

.popup-text {
  padding-left: 10px;
}

.title-bullet {
  list-style: none;
  display: inline-block;
  text-align: center;
  padding-right: 10px;
  font-size: 11px;
}

.non-spatial {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  user-select: none;
  background-color: white;
  z-index: 1;
}

.non-spatial button {
  float: right;
  width: 38px !important;
  height: 38px !important;
  background-color: rgba(255,255,255,1) !important;
  border: none;
}

.non-spatial button:focus, button:active {
  outline: none
}

.non-spatial-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  max-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: var(--non-spatial-content-z);
  top: 3em;
  right: 0.5em;
  min-height: 200px;
  overflow-y: scroll;
  padding: 0 1em 0 0;
  margin: 0;
  border: 4px solid #eee;
  border-radius: 4px;
  max-height: 90%;
  overflow-y: auto;
  margin-left: 25px;
}

.map-app-legend {
  position: absolute;
  bottom: 0.5em;
  left: 0.5em;
  user-select: none;
  border-radius: 4px;
  padding: 2px;
  background-color: rgba(255,255,255,0.9);
  z-index: 1;
  display: block;
  max-width: 100%;
  max-height: 80%;
  overflow-y: scroll;
  min-width: 200px;
  min-height: 160px;

}

.map-app-legend-title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0 10px 0;
}

.map-app-legend-group-content {
  padding-bottom: 10px;

}

.map-app-legend-group-title {
  font-weight: bold;
  padding-left: 9px;
  display: inline;
}

.map-app-legend-list {
  list-style: none;
  padding: 10px 0 0 25px;
  margin-right: 10px;
}

.map-app-legend-list>li {
  height: 35px;
}

.map-app-legend-color-box {
  vertical-align: middle;
  height: 25px;
  width: 40px;
  display: inline-block;
  border-width: 2px;
}

.map-app-legend-item:hover .map-app-legend-list-text {
  text-shadow:0px 0px 1px black;
}

.map-app-legend-item:hover .map-app-legend-color-box {
  border-style: solid;
  border-color: black;
  border-radius: 2px;
}

.map-app-legend-list-text{
  vertical-align: middle;
  display: inline-block;
  margin-left: 15px;
}

.show-content {
  display: block !important;
}

.hide {
  display: none;
}

.non-spatial-list-item {
  margin-left: 25px;
  list-style: none;
}

/* This is all for making the collapsing arrows in non spatial dropdown */
.grouping-fold-button {
  display: inline-block;
  vertical-align: top;
  float: none;
  width: 16px;
  height: 16px;
  padding: 0 !important;
  background-color: transparent !important;
  border-color: transparent !important;
  background-position: center 2px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out !important; 
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
  text-decoration: none;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAW0lEQVR4nGNgGAWMyBwXFxcGBgaGeii3EU0tXHzPnj1wQRYsihqQ+I0ExDEMQAYNONgoAN0AmMkNaDSyQSheY8JiaCMOGzE04zIAmyFYNTMw4A+DRhzsUUBtAADw4BCeIZkGdwAAAABJRU5ErkJggg==');
  background-repeat: no-repeat;
}

.list-button-show {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

/* End of non-spatial dropdown */

.zoom-to-extent {
  position: absolute;
  top: 3.75em;
  left: 0.5em;
  user-select: none;
  z-index: 0;
}

/* Font awesome circles */
.svg-inline--fa.fa-circle {
  vertical-align: 0.225em;
}
/* Font awesome map markers */
.svg-inline--fa.fa-map-marker-alt, .svg-inline--fa.fa-file-alt {
  vertical-align: 0;

}
.layer-switcher>button>.svg-inline--fa.fa-map-marker-alt>path {
  fill: rgba(0,60,136,0.5);
}
#non-spatial-button>.svg-inline--fa.fa-file-alt>path {
  fill: rgba(0,60,136,0.5);
}

.loading-screen {
  position: absolute;
  z-index: var(--loading-screen-z);
  width: 100%;
  background-color: #f8f9fa;
}

.loading-screen-map {
  position: absolute;
  top: 65px;
  bottom: 0px;
  opacity: 1;
}

.loading-screen-top-nav {
  opacity: 0.8;
  top: -65px;
  height: 65px;
}

.load-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -115px;
  background-image: url(./images/load-spinner-new100px.gif);
  background-repeat: no-repeat;
  opacity: 1;
}

.expanded-timeline {
  position: absolute;
  display: block;
  height: 370px;
  min-height: 235px;
  left: 0;
  right:0;
  background-color: #f8f9fa;
  z-index:   var(--expanded-timeline);

  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)
}
.expanded-timeline.hide-timeline  {
  display: none;
}

.search-bar {
  display: inline-block;
  top: 65px;
  bottom: 300px;
  right: 90%;
  margin-left: 15px;
  width: 77%;
  background-color: #f8f9fa;
  z-index:   var(--expanded-timeline);
}

.search-collapse-button {
  margin: 0 0 0 10px;
}

.search-expand-button {
  margin: 10px 0 10px 10px;
}

.search-results {
  margin-top: 10px;
  width: 100%;
  padding-left: 15px;
  height: calc(100% - 10px);
  overflow-y: scroll;
}

td.search-result-col {
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

hr {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.search-group {
  display: inline-block;
  padding-top: 10px;
  width: 350px;
  height: calc(100% - 60px);
}

.graph-container {
  display: inline-block;
  position: absolute;
  width: 100%;
  width: calc(100% - 350px);
  height: calc(100% - 60px);
}

.graph-container.full {
  width: calc(100% - 47px);
}

#graph-contents {
  display: inline-block;
  overflow-y: scroll;
  height: 100%;
  position: relative;
  top: 40px;
  padding-right: 20px;
  width: 100%;

}


#graph{
  margin-left: 300px;
  width: calc(100% - 350px) !important;
}

#graph-labels{
  width: 250px;
  float: left;
  padding-left: 10px;
  user-select: none;
}

.ol-scale-line{
  background: rgba(0,60,136,0.6) !important;
  left: unset !important;
  bottom: 25px !important;
  right: 8px !important;
}

#arrows {
  float: left;
  width: 20px;
  margin-left: 19px;
}
.graph-close-button {
  height: 35px;
  padding-top: 2px;
  cursor: pointer;
  vertical-align: middle;
  opacity: 0.4;
}

.graph-close-button:hover {
  opacity: 1;
}

.graph-arrow {
  cursor: pointer;
  opacity: 0.4;
}

.graph-arrow:hover {
  opacity: 1;
}

#graph-legend {
  position: absolute;
  left: 289px;
  top: -1px;
}

.modal-backdrop {
  z-index: var(--modal-backdrop) !important;
}

.fade.modal.show {
z-index: var(--modal-backdrop) !important;
}

#search-results-table {
  text-align: left;
  position: relative;
}

.search-table-header {
  background: white;
  position: sticky;
  top: -1px;
}

.feature-filter-checkbox {
  margin-bottom: 0;
  font-size: 14px;
}

#hoverHelp {
  top: 10px !important;
}

.preload-list-format {
  font-size: inherit;
  font-family: inherit;
  margin: 0px !important;
}
